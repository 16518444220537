import styled, { createGlobalStyle, css } from 'styled-components';

export const GlobalStyleInternal = createGlobalStyle`
  ${({ theme, ...props }) => {
    return css`
      body {
        background: ${theme.color.primary_100};
        // The first container id="__next" container
        // NOTE: the :first-child as did before, does not work as we can have a script tag as the first child
        > div[id='__next'] {
          height: 100vh;
        }
      }
    `;
  }}
  ;
`;

export const Main = styled.main<{ sticky?: boolean }>`
  ${({ theme, sticky, ...props }) => {
    return css`
      ${!sticky &&
      css`
        position: relative;
        top: -6rem; // height of navbar
        margin-bottom: -6rem; // fix the space created by the negative top space
        z-index: 1;
      `};
    `;
  }};
`;
